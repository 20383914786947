import { Variants } from "framer-motion"

export const MainHeaderMenuBar: Variants = {
  close: {
    left: 0,
    x: 0,
    y: 0,
    rotate: "0deg",
  },
  open: {
    left: 0,
    x: 0,
    y: 10,
    rotate: "-45deg",
  },
}

export const anim: { [key: string]: Variants } = {
  I: { ...MainHeaderMenuBar },
  II: {
    ...MainHeaderMenuBar,
    open: {
      ...MainHeaderMenuBar.open,
      x: -15,
      opacity: 0,
    },
  },
  III: {
    ...MainHeaderMenuBar,
    open: { ...MainHeaderMenuBar.open, y: -10, rotate: "45deg" },
  },
}

export const MainHeaderNavigation: Variants = {
  close: {
    y: "0%",
    opacity: 0,
    transition: {
      duration: 0.24,
      delay: 0.32,
    },
  },
  open: {
    y: "0%",
    opacity: 1,
  },
}

export const MainHeaderNavigationItem: Variants = {
  close: custom => {
    return {
      y: "30%",
      opacity: 0,
      transition: { ease: "circOut", delay: 0.24 * (3 - custom) },
    }
  },
  open: custom => {
    return {
      y: "0%",
      opacity: 1,
      transition: {
        ease: "circOut",
        delay: 0.24 * custom + 0.24,
      },
    }
  },
}
