import { css } from "styled-components"

import { responsiveTypography } from "common/functions"
import { spacing, typography } from "common/styles/settings"
import { T_Padding } from "common/types"

export const modifiers = {
  position: {
    absolute: () => css`
      position: absolute;
      z-index: 1;
    `,
    absoluteFull: () => css`
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    `,
    relativeFrontAbsoluteFull: () => css`
      position: relative;
      z-index: 2;
    `,
  },
  display: {
    block: () => css`
      display: block;
    `,
    inlineBlock: () => css`
      display: inline-block;
    `,
    flex: () => css`
      display: flex;
    `,
  },
  alignItems: {
    alignCenter: () => css`
      align-items: center;
    `,
    alignEnd: () => css`
      align-items: flex-end;
    `,
  },
  justifyContent: {
    spaceBetween: () => css`
      justify-content: space-between;
    `,
    justifyCenter: () => css`
      justify-content: center;
    `,
  },
  gradient: {
    whiteToTransparent: () => css`
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
    `,
    blackToTransparent: () => css`
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(0, 0, 0, 0) 100%
      );
    `,
  },
  spacings: {
    spacingTop: ({ spacingTop }: { spacingTop: T_Padding }) => css`
      padding-top: ${spacingTop ? spacing(spacingTop) : 0};
    `,
    spacingBottom: ({ spacingBottom }: { spacingBottom: T_Padding }) => css`
      padding-bottom: ${spacingBottom ? spacing(spacingBottom) : 0};
    `,
    spacingRight: ({ spacingRight }: { spacingRight: T_Padding }) => css`
      padding-right: ${spacingRight ? spacing(spacingRight) : 0};
    `,
    spacingLeft: ({ spacingLeft }: { spacingLeft: T_Padding }) => css`
      padding-left: ${spacingLeft ? spacing(spacingLeft) : 0};
    `,
  },
  flex: {
    alignCenter: () => css`
      align-items: center;
    `,
  },
  font: {
    transform: {
      textTransform: () => css`
        text-transform: uppercase;
        letter-spacing: 0.1rem;
      `,
    },
    align: {
      fontAlignCenter: () => css`
        text-align: center;
      `,
      fontAlignLeft: () => css`
        text-align: left;
      `,
      fontAlignRight: () => css`
        text-align: right;
      `,
    },
    sizes: {
      mega: () => css`
        ${typography.mega.general}
        ${responsiveTypography(typography.mega.sizes)}
      `,
      advert: () => css`
        ${typography.advert.general}
        ${responsiveTypography(typography.advert.sizes)}
      `,
      headline: () => css`
        ${typography.headline.general}
        ${responsiveTypography(typography.headline.sizes)}
      `,
      subline: () => css`
        ${typography.subline.general}
        ${responsiveTypography(typography.subline.sizes)}
      `,
      tertiary: () => css`
        ${typography.sublineSecondary.general}
        ${responsiveTypography(typography.sublineSecondary.sizes)}
      `,

      title: () => css`
        ${typography.title.general}
        ${responsiveTypography(typography.title.sizes)}
      `,
      paragraph: () => css`
        ${typography.paragraph.general}
        ${responsiveTypography(typography.paragraph.sizes)}
      `,
      body: () => css`
        ${typography.body.general}
        ${responsiveTypography(typography.body.sizes)}
      `,
      small: () => css`
        ${typography.small.general}
        ${responsiveTypography(typography.small.sizes)}
      `,
      xSmall: () => css`
        ${typography.xSmall.general}
        ${responsiveTypography(typography.xSmall.sizes)}
      `,
    },
  },
}
