import styled from "styled-components";

import { applyModifiers } from "common/functions/";
import { color } from "common/settings";
import { T_Padding } from "common/types";
import { Modifiers, T_TextModifiers } from "./TextModifiers";
import { T_TextType } from "../";

export interface I_Text {
  type?: T_TextType;
  weight?: number;
  modifiers?: T_TextModifiers[];
  spacingTop?: T_Padding;
  color?: keyof typeof color;
  spacingBottom?: T_Padding;
  spacingLeft?: T_Padding;
  spacingRight?: T_Padding;
}

const Text = styled.div<I_Text>`
  ${(props) => applyModifiers(Modifiers, props.modifiers)}
`;

const TextStyles = {
  Text,
};

export { TextStyles };
