import styled, { css } from "styled-components";

import { BreakpointsSizes } from "common/settings/breakpoints";
import { applyModifiers } from "common/functions";
import { breaks } from "common/styles/settings";
import { modifiers as mods } from "common/styles";

const {
  alignItems: { alignCenter, alignEnd },
  display: { flex, block },
  justifyContent: { justifyCenter },
} = mods;

export type T_GridPoints = {
  [key in BreakpointsSizes]?: { start?: number; end?: number; span?: number };
};
export type T_OrderPoints = { [key in BreakpointsSizes]?: { order: number } };
type T_GridItem = keyof typeof Modifiers;

export interface I_GridItem {
  responsive?: T_GridPoints;
  order?: T_OrderPoints;
  modifiers?: T_GridItem[];
  justifyEndXs?: boolean;
}

const Modifiers = {
  flex,
  block,
  alignCenter,
  alignEnd,
  justifyCenter,
  justifyEnd: (props: I_GridItem) => {
    const { justifyEndXs } = props;
    return css`
      display: flex;
      justify-content: flex-end;

      ${breaks.xs.down} {
        justify-content: ${justifyEndXs ? "initial" : "flex-end"};
      }
    `;
  },
};

export const GridItem = styled.div<I_GridItem>`
  grid-row: auto;
  grid-column-start: 1;
  grid-column-end: 13;

  ${(props) => applyModifiers(Modifiers, props.modifiers)}
  ${(props) => gridBuilder(props.responsive)}
  ${({ order }) => responsiveOrder(order)}
`;

function responsiveOrder(orderPoints: T_OrderPoints | undefined) {
  if (orderPoints) {
    const providedOrder = Object.keys(orderPoints) as BreakpointsSizes[];

    const renderOrder = (breakPoint: BreakpointsSizes) =>
      breakPoint === "xs"
        ? css`
            ${breaks.sm.down} {
              order: ${orderPoints[breakPoint]?.order};
            }
          `
        : css`
            ${breaks[breakPoint].up} {
              order: ${orderPoints[breakPoint]?.order};
            }
          `;

    const points = providedOrder.map((breakPoint: BreakpointsSizes) =>
      renderOrder(breakPoint)
    );

    return points;
  }
}

function gridBuilder(gridPoints: T_GridPoints | undefined) {
  if (gridPoints) {
    const providedBreakpoints = Object.keys(gridPoints) as BreakpointsSizes[];

    const renderGridpointSpanUndefined = (breakPoint: BreakpointsSizes) =>
      breakPoint === "xs"
        ? css`
            ${breaks[breakPoint].down} {
              grid-column-start: ${gridPoints[breakPoint]?.start};
              grid-column-end: ${gridPoints[breakPoint]?.end};
            }
          `
        : css`
            ${breaks[breakPoint].up} {
              grid-column-start: ${gridPoints[breakPoint]?.start};
              grid-column-end: ${gridPoints[breakPoint]?.end};
            }
          `;

    const renderGridpointSpanDefined = (breakPoint: BreakpointsSizes) =>
      breakPoint === "xs"
        ? css`
            ${breaks.sm.down} {
              grid-column: ${gridPoints[breakPoint]?.start
                  ? gridPoints[breakPoint]?.start
                  : "auto"} / span ${gridPoints[breakPoint]?.span};
            }
          `
        : css`
            ${breaks[breakPoint].up} {
              grid-column: ${gridPoints[breakPoint]?.start
                  ? gridPoints[breakPoint]?.start
                  : "auto"} / span ${gridPoints[breakPoint]?.span};
            }
          `;

    const grid = providedBreakpoints.map((breakPoint: BreakpointsSizes) =>
      gridPoints[breakPoint]?.span === undefined
        ? renderGridpointSpanUndefined(breakPoint)
        : renderGridpointSpanDefined(breakPoint)
    );

    return grid;
  }
}
