import styled from "styled-components"
import { pxToRem } from "common/functions"

export const LogoCircle = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  border-radius: 50%;
  transform: translate(-50%, -50%);
`
