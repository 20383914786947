import React, { useState } from "react"

import { Grid, GridItem } from "components/Grid"
import { Logo } from "components/Logo"
import { MainHeaderMenu } from "./MainHeaderMenu"
import { MainHeaderNavigation } from "./MainHeaderNavigation"

import { MainHeader as MH } from "./styles"

interface Props {
  location: Location
  lang: string
}

export const MainHeader: React.FC<Props> = props => {
  const { location, lang } = props
  const [open, setOpen] = useState<boolean>(false)

  const renderNavigation = () => (
    <MainHeaderNavigation
      lang={lang}
      open={open}
      delegateOpen={open => setOpen(open)}
    />
  )

  return (
    <MH.Block>
      <Grid
        modifiers={["maxWidth", "spacingTop", "spacingBottom"]}
        spacingTop={"xl"}
        spacingBottom={"xl"}
      >
        <GridItem responsive={{ xs: { span: 6 }, sm: { span: 6 } }}>
          {location.pathname !== "/" && <Logo modifiers={["inMainHeader"]} />}
        </GridItem>
        <GridItem
          responsive={{ xs: { span: 6 }, sm: { span: 6 } }}
          modifiers={["flex", "justifyEnd", "alignCenter"]}
        >
          <MainHeaderMenu open={open} delegateOpen={open => setOpen(open)} />
        </GridItem>
      </Grid>
      {renderNavigation()}
    </MH.Block>
  )
}
