import React from "react"
import { v4 as uuidv4 } from "uuid"

export function splitContent(
  content: string,
  classes?: string,
  classesII?: string
) {
  const contentSplit = content.split("[")

  return contentSplit.map((chunk, index) => (
    <React.Fragment key={uuidv4()}>
      {chunk}
      {index < contentSplit.length - 1 ? (
        <>
          <br className={classes ? classes : undefined} />
          {classesII && (
            <span className={classesII ? classesII : undefined}>&nbsp;</span>
          )}
        </>
      ) : (
        ""
      )}
    </React.Fragment>
  ))
}
