import styled from "styled-components"
import { motion as m } from "framer-motion"
import { pxToRem } from "common/functions"
import { color } from "common/settings"
import { breaks } from "common/styles"

export const MainHeaderMenu = styled.div`
  position: relative;
  z-index: 11;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: relative;

  width: ${pxToRem(32)};
  height: ${pxToRem(24)};

  ${breaks.lg.up} {
    width: ${pxToRem(32)};
    height: ${pxToRem(24)};
  }

  cursor: pointer;
`

export const MainHeaderMenuBar = styled(m.div)`
  width: 100%;
  height: ${pxToRem(2)};
  background-color: ${color.dark};

  &:nth-of-type(2) {
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
  }
`
