import React from "react"
import { v4 as uuid } from "uuid"
import { AnimatePresence, motion as m } from "framer-motion"
import { MainLayout } from "./src/layouts/"
import { animation as a } from "./src/layouts/Main/animation"

export const wrapRootElement = ({ element }) => (
  <MainLayout>{element}</MainLayout>
)

export const wrapPageElement = ({ element, props }) => (
  <AnimatePresence exitBeforeEnter>
    <m.div
      style={{ position: "relative" }}
      key={uuid()}
      initial={"initial"}
      animate={"in"}
      exit={"exit"}
      variants={a.layout}
    >
      {element}
    </m.div>
  </AnimatePresence>
)

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  if (location.action === "PUSH") {
    window.setTimeout(() => window.scrollTo(0, 0), 0.24)
  } else {
    const savedPosition = getSavedScrollPosition(location)
    window.setTimeout(() => window.scrollTo(...(savedPosition || [0, 0])), 0.24)
  }
  return false
}
