import { MainHeader as Block } from "./MainHeader"
import {
  MainHeaderMenu as Element,
  MainHeaderMenuBar as Bar,
} from "./MainHeaderMenu"
import {
  MainHeaderNavigation as Navigation,
  MainHeaderNavigationItem as Item,
} from "./MainHeaderNavigation"

export const MainHeader = {
  Block,
  Menu: {
    Element,
    Bar,
  },
  Navigation: {
    Element: Navigation,
    Item,
  },
}
