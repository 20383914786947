export type BreakpointsSizes =
  | "xs"
  | "sm"
  | "md"
  | "lg"
  | "xl"
  | "xxl"
  | "xxxl"
  | "xxxxl";

export const breakpoints: { [key in BreakpointsSizes]: number } = {
  xs: 480,
  sm: 540,
  md: 768,
  lg: 1024,
  xl: 1366,
  xxl: 1680,
  xxxl: 1920,
  xxxxl: 2190,
};
