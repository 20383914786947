import { css } from "styled-components";
import { modifiers as mods } from "common/styles/modifiers";
import { pxToRem } from "common/functions";
import { gaps } from "common/styles/settings";
import { color as globalColor } from "common/settings";
// deconstruct global modifiers

const {
  spacings: { spacingTop, spacingBottom, spacingLeft, spacingRight },
  display: { block },
  position: { relativeFrontAbsoluteFull },
  font: {
    align: { fontAlignCenter, fontAlignRight },
    sizes: {
      mega,
      advert,
      headline,
      subline,
      tertiary,
      title,
      paragraph,
      body,
      small,
      xSmall,
    },
    transform: { textTransform },
  },
} = mods;

export type T_TextModifiers = keyof typeof Modifiers;

export const Modifiers = {
  relativeFrontAbsoluteFull,
  // text sizes
  mega,
  advert,
  headline,
  subline,
  tertiary,
  title,
  paragraph,
  body,
  small,
  xSmall,
  // display
  block,
  // alignment
  fontAlignCenter,
  fontAlignRight,
  // spacings
  spacingTop,
  spacingBottom,
  spacingLeft,
  spacingRight,
  // transform values
  textTransform,

  lineHeight: () => css`
    line-height: 1.2;
  `,
  lineHeightText: () => css`
    line-height: 1.55;
  `,

  wordBreak: () => css`
    word-break: break-all;
  `,

  asTag: () => css`
    padding: ${gaps.s} ${gaps.m};
    border-radius: ${pxToRem(4)};
  `,

  fontFamilyExtended: () => css`
    font-family: "Neufile Grotesk Extended";
  `,

  fontFamilyDefault: () => css`
    font-family: "Neufile Grotesk";
  `,

  fontWeight: ({ weight }: { weight: number }) => {
    return css`
      font-weight: ${weight} !important;
    `;
  },

  color: ({ color }: { color: keyof typeof globalColor }) => {
    return css`
      color: ${globalColor[color]} !important;
    `;
  },

  underLine: () => css`
    position: relative;
    display: inline-block;

    &:after {
      content: "";
      position: absolute;
      bottom: ${gaps.l};
      left: 0;
      width: 100%;
      height: ${pxToRem(1)};
      background-color: grey;
    }
  `,
};
