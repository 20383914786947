import React from "react"
import { v4 as uuidv4 } from "uuid"
import { Link, useStaticQuery, graphql } from "gatsby"
import { Grid, GridItem } from "components/Grid"
import { Text } from "components/Text"

interface I_NavItem {
  page: {
    lang: string
    slug: string
    title: string
  }
}

interface Props {
  location: Location
  lang: string
}

function getTeaserLinks(): { allContent: { pages: I_NavItem[] } } {
  return useStaticQuery(graphql`
    query getLinks {
      allContent: allContentJson {
        pages: edges {
          page: node {
            lang
            slug
            title
          }
        }
      }
    }
  `)
}

export const TeaserLinks: React.FC<Props> = props => {
  const {
    allContent: { pages },
  } = getTeaserLinks()

  const { lang, location } = props

  const purgedLinks = pages.filter(
    ({ page }) =>
      page.slug !== "legal" && page.slug !== location.pathname.slice(1)
  )

  const purgedLangNavigation = purgedLinks.filter(
    ({ page }: I_NavItem) => page.lang === lang
  )

  const renderLink = (title: string, to: string) => (
    <Text
      key={uuidv4()}
      modifiers={["headline", "spacingBottom"]}
      spacingBottom={"xxxl"}
    >
      <Link to={`/${to}`}>{title}</Link>
    </Text>
  )

  return (
    <Grid
      modifiers={["maxWidth", "spacingTop", "spacingBottom"]}
      spacingTop={"xxxl"}
      spacingBottom={"xxxl"}
    >
      <GridItem>
        <Text
          modifiers={["spacingTop", "spacingBottom"]}
          spacingTop={"xxxl"}
          spacingBottom={"xxxl"}
        >
          <></>
        </Text>
        <Text
          modifiers={[
            "paragraph",
            "fontAlignCenter",
            "spacingTop",
            "spacingBottom",
          ]}
          spacingTop={"m"}
          spacingBottom={"xxxl"}
        >
          Learn About
        </Text>
      </GridItem>
      <GridItem>
        {purgedLangNavigation.map(
          ({ page }) =>
            page.slug !== location.pathname.slice(0, 1) &&
            renderLink(page.title, page.slug)
        )}
      </GridItem>
      <GridItem></GridItem>
    </Grid>
  )
}
