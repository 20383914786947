import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { AnimatePresence } from "framer-motion"

import { transition } from "common/settings"
import { Grid, GridItem } from "components/Grid"

import { MainHeader as MH } from "../styles"
import {
  MainHeaderNavigation as anim,
  MainHeaderNavigationItem as animItem,
} from "../animation"
import { Text } from "components/Text"

interface I_NavItem {
  page: {
    lang: string
    slug: string
    title: string
  }
}

interface Props {
  open: boolean
  lang: string
  delegateOpen: (open: boolean) => void
}

function getNavigationElements() {
  return useStaticQuery(graphql`
    query getNavigation {
      allContent: allContentJson {
        pages: edges {
          page: node {
            lang
            slug
            title
          }
        }
      }
    }
  `)
}

const langs = ["en", "hr"]

export const MainHeaderNavigation: React.FC<Props> = props => {
  const { open, lang, delegateOpen } = props

  const {
    allContent: { pages },
  } = getNavigationElements()

  const navigation = pages.filter(({ page }: I_NavItem) => page.slug !== "home")
  const purgedLangNavigation = navigation.filter(
    ({ page }: I_NavItem) => page.lang === lang
  )

  const renderNavigation = (el: JSX.Element) => (
    <MH.Navigation.Element
      initial={"close"}
      animate={"open"}
      exit={"close"}
      variants={anim}
      transition={{ ...transition }}
    >
      <Grid modifiers={["maxWidth"]}>
        <GridItem modifiers={["flex", "alignCenter"]}>
          <div>
            {el}
            <br />
            <br />
            <div>
              {langs.find(
                lng => lng !== lang && <Link to={`${lng}`}>{lng}</Link>
              )}
            </div>
          </div>
        </GridItem>
      </Grid>
    </MH.Navigation.Element>
  )

  const renderNavigationItem = ({ page }: I_NavItem, index: number) => (
    <MH.Navigation.Item
      custom={index}
      initial={"close"}
      animate={"open"}
      exit={"close"}
      variants={animItem}
    >
      <Text
        type="span"
        modifiers={["block", "tertiary", "spacingTop"]}
        spacingTop={"l"}
      >
        <Link
          onClick={() => {
            delegateOpen(!open)
          }}
          to={`/${page.slug}`}
        >
          {page.title}
        </Link>
      </Text>
    </MH.Navigation.Item>
  )

  return (
    <AnimatePresence exitBeforeEnter>
      {open &&
        renderNavigation(
          purgedLangNavigation.map((page: I_NavItem, index: number) =>
            renderNavigationItem(page, index)
          )
        )}
    </AnimatePresence>
  )
}
