import { gaps } from "common/styles";
import { T_Padding } from "common/types";

export const spacing = (size: T_Padding) => {
  switch (size) {
    case "xs":
      return gaps.xs;
    case "s":
      return gaps.s;
    case "m":
      return gaps.l;
    case "l":
      return gaps.xl;
    case "xl":
      return gaps.xxl;
    case "xxl":
      return gaps.xxxl;
    case "xxxl":
      return gaps.xxxxl;
  }
};
