import { breakpoints } from "common/settings";
export type TBreakpoints = keyof typeof breakpoints;

const breakpointKeys = Object.keys(breakpoints) as TBreakpoints[];
let breaks = {} as { [key in TBreakpoints]: { up: string; down: string } };

breakpointKeys.map(
  (bp: TBreakpoints) =>
    (breaks[bp] = {
      up: `@media (min-width: ${breakpoints[bp]}px)`,
      down: `@media (max-width: ${breakpoints[bp]}px)`,
    })
);

export { breaks };
