import React from "react"
import { GlobalStyles } from "common/styles/"

interface Props {
  children: React.ReactNode
}

export const MainLayout: React.FC<Props> = props => {
  const { children } = props

  return (
    <div>
      <GlobalStyles />
      {children}
    </div>
  )
}
