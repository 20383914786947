import React from "react"
import { MainHeader } from "components/MainHeader"
import { Footer } from "components/Footer"

interface Props {
  children: React.ReactNode
  location: Location
  lang: string
}

export const ContentLayout: React.FC<Props> = props => {
  const { children, location, lang } = props

  return (
    <>
      <MainHeader lang={lang} location={location} />
      <div style={{ width: "100vw", overflow: "hidden" }}>{children}</div>
      <Footer lang={lang} location={location} />
    </>
  )
}
