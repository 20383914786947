import React from "react"
import { Grid, GridItem } from "components/Grid"
import { TeaserLinks } from "components/Teasers/TeaserLinks"
import { Text } from "components/Text"
import { Svg } from "components/Svg"

interface Props {
  lang: string
  location: Location
}

export const Footer: React.FC<Props> = props => {
  const date = new Date()
  const { lang, location } = props

  return (
    <>
      <TeaserLinks lang={lang} location={location} />
      <footer>
        <Grid
          modifiers={["maxWidth", "spacingTop", "spacingBottom"]}
          spacingTop={"xxxl"}
          spacingBottom={"xl"}
        >
          <GridItem responsive={{ md: { span: 6 } }}>
            <div style={{ maxWidth: 180 }}>
              <Svg type="getLogo" />
            </div>
            <Text
              type={"span"}
              modifiers={["block", "small", "spacingTop"]}
              spacingTop={"xl"}
            >
              ©&nbsp;{date.getFullYear()}&nbsp;GET Holding d.o.o.
            </Text>
          </GridItem>
          <GridItem
            modifiers={["flex", "alignEnd", "justifyEnd"]}
            responsive={{ md: { span: 6 } }}
          >
            <Text type={"span"} modifiers={["small"]}>
              Legal
            </Text>
            <Text
              type={"span"}
              modifiers={["small", "spacingLeft"]}
              spacingLeft={"l"}
            >
              Data Privacy
            </Text>
          </GridItem>
        </Grid>
      </footer>
    </>
  )
}
