import React from "react"
import { Svg } from "components/Svg"
import { Logo as L } from "./styles"
import { T_Logo } from "./styles/Logo.styles"

interface Props {
  modifiers?: T_Logo[]
}

export const Logo: React.FC<Props> = props => {
  const { modifiers } = props
  return (
    <L.Block to={"/"} modifiers={modifiers}>
      <Svg type={"getLogo"} />
      <L.Circle>
        <Svg type={"circles"} />
      </L.Circle>
    </L.Block>
  )
}
