import React from "react"
import { splitContent } from "common/functions"
import { TextStyles as TXT, I_Text } from "./styles"

export type T_TextType = "h1" | "h2" | "h3" | "h4" | "p" | "small" | "span"

export interface TextProps extends I_Text {
  children: React.ReactNode
}

export const Text: React.FC<TextProps> = ({
  type,
  children,
  color,
  modifiers,
  weight,
  spacingTop,
  spacingBottom,
  spacingLeft,
  spacingRight,
}) => (
  <TXT.Text
    as={type}
    weight={weight}
    color={color}
    modifiers={modifiers}
    spacingTop={spacingTop}
    spacingBottom={spacingBottom}
    spacingLeft={spacingLeft}
    spacingRight={spacingRight}
  >
    {typeof children === "string" ? splitContent(children) : children}
  </TXT.Text>
)
